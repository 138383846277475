import {getFormattedLocation} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import React from 'react'
import {useEventDateInformation} from '../../../../../../../commons/hooks/dates'
import {useMobileFontOverrides} from '../../../../../../../commons/hooks/use-mobile-font-overrides'
import {useVisibilityStyles} from '../../../../../hooks/use-visibility-styles'
import cl from '../../../classnames.scss'
import {ShortDateLocationProps} from './interfaces'
import s from './short-date-location.scss'

const separator = '  |  '

export const ShortDateLocation = ({event, mobile}: ShortDateLocationProps) => {
  const {
    allBreakpoints: {isShortDateLocationVisible},
  } = useVisibilityStyles()
  const {shortStartDate} = useEventDateInformation(event.id)
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()

  if (!isShortDateLocationVisible()) {
    return null
  }

  return (
    <div
      className={classNames(s.container, cl.evShortDateLocationFont, cl.evShortDateLocationColor, {
        [s.mobile]: mobile,
        [s.mobileFontSize]: mobile && mobileFontOverridesEnabled,
      })}
      data-hook={DH.headerShortDateLocation}
      role="text"
    >
      <p className={s.date} data-hook={DH.headerShortDate}>
        {shortStartDate}
      </p>
      <span className={s.separator}>{separator}</span>
      <p className={s.location} data-hook={DH.headerShortLocation}>
        {getFormattedLocation(event)}
      </p>
    </div>
  )
}
